import { createGlobalStyle } from "styled-components";

import robotoEOT from "./roboto-v27-latin-regular.eot";
import robotoTTF from "./roboto-v27-latin-regular.ttf";
import robotoSVG from "./roboto-v27-latin-regular.svg";
import robotoWOFF from "./roboto-v27-latin-regular.woff";
import robotoWOFF2 from "./roboto-v27-latin-regular.woff2";

import vt323EOT from "./vt323-v12-latin-regular.eot";
import vt323TTF from "./vt323-v12-latin-regular.ttf";
import vt323SVG from "./vt323-v12-latin-regular.svg";
import vt323WOFF from "./vt323-v12-latin-regular.woff";
import vt323WOFF2 from "./vt323-v12-latin-regular.woff2";

export default createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('${robotoEOT}'); /* IE9 Compat Modes */
    src: local(''),
         url('${robotoEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('${robotoWOFF2}') format('woff2'), /* Super Modern Browsers */
         url('${robotoWOFF}') format('woff'), /* Modern Browsers */
         url('${robotoTTF}') format('truetype'), /* Safari, Android, iOS */
         url('${robotoSVG}#Roboto') format('svg'); /* Legacy iOS */
  }

  /* vt323-regular - latin */
  @font-face {
    font-family: 'VT323';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/vt323-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('${vt323EOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('${vt323WOFF2}') format('woff2'), /* Super Modern Browsers */
        url('${vt323WOFF}') format('woff'), /* Modern Browsers */
        url('${vt323TTF}') format('truetype'), /* Safari, Android, iOS */
        url('${vt323SVG}#Roboto') format('svg'); /* Legacy iOS */
  }
`;
