import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const MenuContainer = styled.div`
  padding: ${(props) => props.theme.twoX};
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: ${(props) => props.theme.spacing.oneX};
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
  background-color: ${(props) => props.theme.colors.secondary};
`;

const TopMenu = styled.div`
  font-family: VT323;
  font-size: ${(props) => props.theme.fontSizes.xlarge};
  color: green;
  padding: ${(props) => props.theme.spacing.oneX};
  border-right: 1px solid ${(props) => props.theme.colors.accent};
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.accent};

  &:visited {
    color: ${(props) => props.theme.colors.accent};
  }

  &:hover {
    color: ${(props) => props.theme.colors.emphasis3};
  }
`;

const Menu = () => (
  <MenuContainer>
    <TopMenu>
      <MenuLink to="/">Home</MenuLink>
    </TopMenu>
    <TopMenu>
      <MenuLink to="/blog">Blog</MenuLink>
    </TopMenu>
  </MenuContainer>
);

export default Menu;
