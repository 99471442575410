const theme = {
  spacing: {
    oneX: "5px",
    twoX: "10px",
    threeX: "15px",
    fourX: "20px",
    fiveX: "25px",
    sixX: "30px",
    sevenX: "35px",
    eightX: "40px",
  },
  colors: {
    text: "#364156",
    primary: "#92B0BA",
    secondary: "#011638",
    highlight: "#8AEA92",
    highlight2: "#DB162F",
    accent: "#FFFFFF",
    emphasis: "#214E34",
    emphasis2: "#364156",
    emphasis3: "green",
  },
  fontSizes: {
    small: "10px",
    medium: "12px",
    large: "18px",
    xlarge: "24px",
    xxlarge: "36px",
    xxxlarge: "48px",
  },
  screens: {
    sm: {
      width: "600px",
    },
    md: {
      width: "768px",
    },
    lg: {
      width: "992px",
    },
    xl: {
      width: "1200px",
    },
    xxl: {
      width: "1400px",
    },
  },
};

export default theme;
