import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  padding: 0px;
  margin: 0px;
}

html > body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;  
  color: ${(props) => props.theme.colors.text}
}

#___gatsby {
  margin:0px;
  padding: 0px;
}

#gatsby-focus-wrapper{
  margin:0px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;  
}
`;

export default GlobalStyle;
