import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import logoImage from "../../images/gatsby-icon.png";
import headerImage from "../../images/header.jpg";
import { SocialIcon } from "react-social-icons";

const Head = styled.header`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid grey;
  margin: 0px;
  position: relative;

  & h1 {
    font-family: "VT323";
    color: ${(props) => props.theme.colors.emphasis3};
    text-shadow: 2px 2px #000000;
    display: inline-block;
    word-break: break-word;
  }

  &::after {
    content: "";
    background-image: url(${headerImage});
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-size: 100%;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const LogoWrapper = styled.div`
  padding: 10px;
`;

const Logo = styled.img`
  width: 100px;
  border-radius: 50%;
  display: inline;
  image-align: middle;
`;

const TitleWrapper = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.xlarge};
  padding: 10px;
  flex-grow: 1;

  @media only screen and (max-width: ${(props) =>
      props.theme.screens.sm.width}) {
    font-size: ${(props) => props.theme.fontSizes.medium};
  }
`;

const SocialWrapper = styled.div`
  float: right;
  padding: ${(props) => props.theme.spacing.twoX};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledSocialIcon = styled(SocialIcon)`
  margin: ${(props) => props.theme.spacing.oneX};
`;

const Header = ({ siteTitle }) => (
  <Head>
    <LogoWrapper>
      <Link to="/">
        <Logo src={logoImage} />
      </Link>
    </LogoWrapper>
    <TitleWrapper>
      <StyledLink to="/">
        <h1>{siteTitle}</h1>
      </StyledLink>
    </TitleWrapper>
    <SocialWrapper>
      <StyledSocialIcon url="https://www.linkedin.com/in/gino-coates-8788aa62" />
    </SocialWrapper>
  </Head>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
