/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import Header from "../Header/header";
import Menu from "../Menu/menu";

import { ThemeProvider } from "styled-components";
import theme from "../Theme/theme.js";
import GlobalFonts from "../Fonts/fonts";
import GlobalStyle from "../Theme/globalStyle.js";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import footerImage from "../../images/header.jpg";
import { SocialIcon } from "react-social-icons";
import rssImage from "../../images/rss.png";

const StyledSocialIcon = styled(SocialIcon)`
  margin: ${(props) => props.theme.spacing.oneX};
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainViewport = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`;

const Main = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.screens.sm.width};
  padding: ${(props) => props.theme.spacing.twoX};
  box-sizing: border-box;
`;

const Footer = styled.footer`
  flex: 1;
  text-align: center;
  padding: ${(props) => props.theme.spacing.threeX};
  position: relative;

  &::after {
    content: "";
    background-image: url(${footerImage});
    background-attachment: fixed;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const FooterContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  word-wrap: normal;
`;

const LinksColumn = styled.div`
  padding: ${(props) => props.theme.spacing.fourX};
`;

const LinkWrapper = styled.div`
  text-align: left;
  @media only screen and (max-width: ${(props) =>
      props.theme.screens.sm.width}) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const RSSImage = styled.img`
  width: 40px;
  vertical-align: middle;
  margin: ${(props) => props.theme.spacing.twoX};
`;

deckDeckGoHighlightElement();

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        <GlobalStyle />
        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Menu />
          <MainViewport>
            <Main>{children}</Main>
          </MainViewport>
          <Footer>
            <FooterContentWrapper>
              <LinksColumn>
                <h4>Stay In Touch</h4>
                <LinkWrapper>
                  <Link to="/rss.xml">
                    <RSSImage src={rssImage} />
                  </Link>
                  Subscribe to my feed
                </LinkWrapper>
                <LinkWrapper>
                  <StyledSocialIcon url="https://github.com/ginocoates" />
                  Github Projects
                </LinkWrapper>
              </LinksColumn>
              <LinksColumn>
                <h4>Connect with Me</h4>
                <LinkWrapper>
                  <StyledSocialIcon url="https://www.linkedin.com/in/gino-coates-8788aa62" />
                  Connect with me on LinkedIn
                </LinkWrapper>
                <LinkWrapper>
                  <StyledSocialIcon url="https://twitter.com/ginocoates" />
                  Follow me on Twitter
                </LinkWrapper>
                <LinkWrapper>
                  <StyledSocialIcon url="https://www.youtube.com/user/vegatrip2008" />
                  Watch my videos
                </LinkWrapper>
                <LinkWrapper>
                  <StyledSocialIcon url="https://www.instagram.com/vegatrip/" />
                  Follow me on Instagram
                </LinkWrapper>
              </LinksColumn>
            </FooterContentWrapper>
            © {new Date().getFullYear()}, Created by me using
            {` `}
            <a href="https://www.gatsbyjs.com">Gatsby</a>
          </Footer>
        </Container>
      </ThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
